import { graphql, Link } from 'gatsby'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import gatsbyConfig from '../../gatsby-config'
import Layout from '../components/Layout'

const PostLink = ({ post }) => {
  console.log(post)
  return (
    <h1 className="text-2xl leading-tight pt-2 pb-0">
      <Link className="no-underline" to={post.frontmatter.slug}>
        {post.frontmatter.title}
      </Link>
    </h1>
  )
}
// markup
const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const yearsAsObject = edges
    .filter((edge) => {
      return edge.node.frontmatter.slug != null
    })
    .reduce((years, edge) => {
      const articleDate = edge.node.frontmatter.date
      if (years[articleDate] == undefined) years[articleDate] = []
      years[articleDate].push(edge)
      return years
    }, {})
  const years = Object.entries(yearsAsObject).sort(
    (left, right) => parseInt(right[0]) - parseInt(left[0])
  )

  const Sections = years.map((section) => {
    const year = section[0]
    const edges: any = section[1]
    const Posts = edges.map((edge) => (
      <article>
        <PostLink key={edge.node.id} post={edge.node} />
        <p className="pb-1">{edge.node.excerpt}</p>
        <p className="pt-0">
          <Link to={edge.node.frontmatter.slug}>Read more...</Link>
        </p>
      </article>
    ))

    return (
      <div>
        <h3 className="text-base pb-0 pt-3 text-gray-400">——— {year} ———</h3>
        {Posts}
      </div>
    )
  })
  const description =
    'Articles about the tech industry, references to books, companies and people inspiring me.'
  return (
    <Layout>
      <Helmet>
        <meta property="og:description" content={description} />
        <meta name="description" content={description} />
        <title>{gatsbyConfig.siteMetadata.title}</title>
      </Helmet>
      <main>
        <p>{description}</p>
        <div>{Sections}</div>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "article" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            title
            slug
            date(formatString: "YYYY")
          }
        }
      }
    }
  }
`

export default IndexPage
